/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.assaLogo {
  height: 18px;
  margin: auto;
}
.menuItem:hover {
  color: black;
}
.menuItem {
  background-color: black;
  color: white;
}

.collapse-btn:hover {
  color: #968049 !important;
}

.collapse-btn {
  background-color: black;
  color: white;
}

.headerRow {
  height: 30px;
  max-height: 30px;
  min-height: 30px;
  color:white;
  font-weight: 600;
  font-size:20px;
  letter-spacing: 3px;  
}

.appPageContainer {
  background-color: rgba(256, 256, 256, 1);
  border-radius: 8px;
  box-shadow: 0px 0px 8px 5px rgba(256, 256, 256, 0.2);
  margin:5px 0px 0px 5px;
  height: calc(100vh - 165px);
  width: calc(100% - 10px);
  padding:10px;
  overflow: auto;
}

.landingPageContainer {
  background-color: rgba(256, 256, 256, 0.7);
  border-radius: 8px;
  box-shadow: 0px 0px 8px 5px rgba(256, 256, 256, 0.2);
  margin:15px 0px 0px 15px;
  height: calc(100vh - 185px);
  width: calc(100% - 30px);
}
/* .landingPageLoginContainer {
  background-color: rgba(150, 128, 73, 0.8);
  background-color: #644876e6;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  margin:25px;
  height: calc(100% - 50px);
  min-width:400px;
  width:23%;  
} */

/* .landingPageDescriptionContainer {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: rgba(35, 31, 32, 0.96);
  border-radius: 8px;
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  margin:25px;
  height: calc(100% - 50px);
  color:white;
  min-width:600px;
  width:69%;
} */

/* .landingPageHeader {
  font-size: 48px;
  margin-left:20px;
  margin-top: 10px;
  font-weight: 800;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* .active .active-side-bar {
  color: #968049;
} */

.text-size {
  font-size: 14px !important;
}

.caps-text-size {
  font-size: 12px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media only screen and (max-width: 1280px) {
  .agreement-modal-size {
    height: 85% !important;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-nav .active {
  color: #968049;
  text-decoration: underline;
}

.top-nav:hover {
  color: #968049;
  text-decoration: underline;
}

.hyper-link:hover {
  color: #1976d2;
  text-decoration: underline;
}

.log-out:hover {
  color: #968049;
}

.button-text-decor {
  text-transform: none !important;
}

.notAllowedCursor,
.Mui-disabled {
  cursor: not-allowed !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #968049 !important;
}

.svgIconStyles {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.checkbox-filled,
.radio-filled {
  color: #968049 !important;
}

.custom-stepper .k-progressbar {
  height: 4px;
  top: 18px;
}

.custom-stepper .k-progressbar .k-selected {
  background: #968049;
}

.custom-step {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.custom-step.completed {
  background-color: rgb(0, 158, 8);
}

.custom-step:hover {
  background-color: #968049;
  cursor: pointer;
}

.step-disable.custom-step {
  background-color: #727272;
  cursor: not-allowed;
}

.step-disable.custom-step.agreement-step {
  background-color: rgb(0, 158, 8);
  cursor: not-allowed;
}

.k-step-link .k-icon {
  font-size: 30px;
  color: #968049;
}

.k-step-link .step-label {
  color: #000;
  font-weight: bold;
}

.k-step-list-horizontal ~ .k-progressbar {
  top: calc((55px + 2 * 2px) / 2 + 2px / 2) !important;
}

.k-header {
  background-color: rgba(224, 224, 224, 1) !important;
  font-weight: 500 !important;
}

.k-step-link {
  max-width: 22rem !important;
}
.k-grid .k-table {
  width: 100% !important;
}

/* Example2 Css */
.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 55px;
  left: 5px;
  width: 1000px;
  height: 400px;
  border: 1px solid #333;
  box-sizing: border-box;
}
 
.target {
  position: absolute;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #ee8;
  color: #333;
  font-weight: bold;
  border: 1px solid #333;
  box-sizing: border-box;
  z-index: 1000;
}
 
.target2 {
  position: absolute;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #ee8;
  color: #333;
  font-weight: bold;
  border: 1px solid #333;
  box-sizing: border-box;
  z-index: 1000;
}

.k-animation-container{
  z-index: 1500 !important;
}

.cursorNotAllowed{
  cursor: not-allowed !important;
  pointer-events: all !important;
}
 